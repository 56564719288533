<template>
  <div class="d-flex flex-nowrap">
    <document-ref-icon
      :visible="showIcon"
      icon-name="i.DunningReminders"
      :mixed="mixed"
      :x-small="_iconProps.xsmall"
      :small="_iconProps.small"
      :normal="_iconProps.normal"
      :large="_iconProps.large"
      :x-large="_iconProps.xlarge"
    />
    <div class="d-flex flex-column">
      <document-number-ref
        :id="id"
        :cache-type="cacheType"
        :show-icon="false"
        :mixed="mixed"
      />
      <slot name="doc-type" />
    </div>
  </div>
</template>

<script>

import ClientCache from '@/mixins/client-cache'

export default {
  inheritAttrs: false,
  components: {
    DocumentRefIcon: () => import('@/components/document-ref-icon'),
    DocumentNumberRef: () => import('@/components/documents-ref/document-number-ref')
  },
  computed: {
    number () {
      return this.id ? this.cache?.doc?.number : this.$t('t.None')
    },
    _iconProps () {
      const _ = Object.assign({
        xsmall: false,
        small: false,
        normal: false,
        large: false,
        xlarge: false
      }, this.iconProps)

      return _
    }
  },
  data () {
    return {
      cacheType: ClientCache.CacheType.DunningRemindersRef
    }
  },
  mixins: [ClientCache],
  props: {
    id: String,
    showIcon: Boolean,
    mixed: Boolean,
    options: Object,
    iconName: String,
    iconProps: Object
  }
}
</script>
